// src/Navbar.tsx
import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';

interface NavBarProps {
    onSignOut: () => void;
}

const Navbar: React.FC<NavBarProps> = ({ onSignOut }) => {
    return (
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
            <div className="container-fluid">
                <Link className="navbar-brand" to="/dashboard">Scooby Inventory Management</Link>
                <button
                    className="navbar-toggler"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarNav"
                    aria-controls="navbarNav"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                >
                    <FontAwesomeIcon icon={faBars} />
                </button>
                <div className="collapse navbar-collapse" id="navbarNav">
                    <ul className="navbar-nav ms-auto">
                        <li className="nav-item">
                            <Link className="nav-link" to="/dashboard/add-inventory">Add Inventory</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/dashboard/search-inventory">Search Inventory</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/dashboard/manage-inventory">Manage Inventory</Link>
                        </li>
                        <li className="nav-item">
                            <button className="nav-link btn btn-link" onClick={onSignOut}>Sign Out</button>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    );
};

export default Navbar;

import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Carousel } from 'react-bootstrap';
import { InventoryItem } from '../Hooks/useFetchInventory';
import QRCode from 'react-qr-code'; // Import the QRCode component

interface InventoryCardProps {
    item: InventoryItem;
}

const InventoryCard: React.FC<InventoryCardProps> = ({ item }) => {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate(`/item/${item.id}`);
    };


    const stopPropagation = (e: React.MouseEvent) => {
        e.stopPropagation();
    };

    const handleQRCodeClick = (e: React.MouseEvent) => {
        e.stopPropagation();
        navigate(`/item/${item.id}/qr`);
    };

    return (
        <div className="card mb-3 shadow-sm" onClick={handleClick} style={{ cursor: 'pointer' }}>
            <div className="card-body">
                <h6 className="card-subtitle text-end text-muted">SKU: {item.id}</h6>
                <Carousel className="mb-3" onClick={stopPropagation} interval={null}>
                    {item.images && item.images.length > 0 && item.images.map((image, index) => (
                        <Carousel.Item key={index}>
                            <img src={image.thumbnail} className="d-block w-100" alt={`Slide ${index}`} />
                        </Carousel.Item>
                    ))}
                    <Carousel.Item onClick={handleQRCodeClick}>
                        <div className="d-flex justify-content-center align-items-center" style={{ height: '300px' }}>
                            <QRCode value={`${window.location.origin}/item/${item.id}`} />
                        </div>
                    </Carousel.Item>
                </Carousel>
                <h5 className="card-title">{item.name}</h5>
                <p className="card-text text-muted">{item.description}</p>
                <p className="card-text">
                    <strong>Category: </strong>
                    {item.category}
                </p>
                <p className="card-text">
                    <strong>Location: </strong>
                    {item.location}
                </p>
                <p className="card-text">
                    <strong>Measurements: </strong>
                    {item.measurements}
                </p>
                <p className="card-text">
                    <strong>Price: </strong>${item.price}
                </p>
                <p className="card-text">
                    <strong>Purchased on: </strong>
                    {new Date(item.date.seconds * 1000).toLocaleDateString()}
                </p>
                <p className="card-text">
                    <strong>Added on: </strong>
                    {new Date(item.createdAt.seconds * 1000).toLocaleDateString()}
                </p>
                <Carousel className="mb-3" interval={null}>
                    {item.descriptions && item.descriptions.length > 0 && item.descriptions.map((descriptions, index) => (
                        <Carousel.Item key={index}>
                            <p className="card-text">{descriptions}</p>
                        </Carousel.Item>
                    ))}
                </Carousel>
            </div>
        </div>
    );
};

export default InventoryCard;
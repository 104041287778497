import React from 'react';
import { useParams } from 'react-router-dom';
import { Container, Row, Col, Carousel, Spinner, Card } from 'react-bootstrap';
import useFetchItemDetails from '../Hooks/useFetchItemDetails'; // Import the custom hook
import QRCode from 'react-qr-code'; // Import the QRCode component
import { useNavigate } from 'react-router-dom';



const ItemDetails: React.FC = () => {
    const navigate = useNavigate();
    const { id = '' } = useParams<{ id: string }>();
    const { item, loading } = useFetchItemDetails(id);
    console.log(item?.descriptions)


    const handleQRCodeClick = () => {
        navigate(`/item/${id}/qr`);
    };

    if (loading) {
        return (
            <div className="d-flex justify-content-center align-items-center vh-100">
                <Spinner animation="border" />
            </div>
        );
    }

    if (!item) {
        return <p>Item not found</p>;
    }

    return (
        <Container className="my-4">
            <Row>
                <Col md={6}>
                    <Carousel interval={null}>
                        {item.images.map((image, index) => (
                            <Carousel.Item key={index}>
                                <a href={image.original} target="_blank" rel="noopener noreferrer">
                                    <img src={image.original} className="d-block w-100" alt={`Slide ${index}`} />
                                </a>
                            </Carousel.Item>
                        ))}
                        <Carousel.Item onClick={handleQRCodeClick}>
                            <div className="d-flex justify-content-center align-items-center" style={{ height: '300px' }}>
                                <QRCode value={`${window.location.origin}/item/${item.id}`} />
                            </div>
                        </Carousel.Item>
                    </Carousel>
                </Col>
                <Col md={6}>
                    <Card>
                        <Card.Body>
                            <Card.Title>{item.name}</Card.Title>
                            <Card.Text>
                                <strong>Category:</strong> {item.category}
                            </Card.Text>
                            <Card.Text>
                                <strong>Price:</strong> ${item.price}
                            </Card.Text>
                            <Card.Text>
                                <strong>Purchased on:</strong> {new Date(item.date.seconds * 1000).toLocaleDateString()}
                            </Card.Text>
                            <Card.Text>
                                <strong>Added on:</strong> {new Date(item.createdAt.seconds * 1000).toLocaleDateString()}
                            </Card.Text>
                            <Card.Text>
                                <strong>Location:</strong> {item.location}
                            </Card.Text>
                            <Card.Text>
                                <strong>Measurements:</strong> {item.measurements}
                            </Card.Text>
                            <Card.Text>
                                <strong>Description:</strong> {item.description}
                            </Card.Text>
                            <Carousel className="mb-3" interval={null}>
                                {item.descriptions && item.descriptions.length > 0 && item.descriptions.map((descriptions, index) => (
                                    <Carousel.Item key={index}>
                                        <p className="card-text">{descriptions}</p>
                                    </Carousel.Item>
                                ))}
                            </Carousel>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default ItemDetails;
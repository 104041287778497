// src/Dashboard.tsx
import React from 'react';
import { signOut } from 'firebase/auth';
import { auth } from '../Hooks/firebaseConfig';
import { Routes, Route } from 'react-router-dom';
import AddInventory from './AddInventory';
import SearchInventory from './SearchInventory';
import ManageInventory from './ManageInventory';
import Navbar from '../Components/Navbar';
import '../CSS/Dashboard.css';

const Dashboard: React.FC = () => {
    const handleSignOut = async () => {
        await signOut(auth);
    };

    return (
        <div>
            <Navbar onSignOut={handleSignOut} />
            <div className="content p-4">
                <Routes>
                    <Route path="add-inventory" element={<AddInventory />} />
                    <Route path="search-inventory" element={<SearchInventory />} />
                    <Route path="manage-inventory" element={<ManageInventory />} />
                </Routes>
            </div>
        </div>
    );
};

export default Dashboard;

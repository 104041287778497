// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyAmRsuISDgCULDRTL1tN0JvoKhtRuh504Q",
    authDomain: "scooby-425119.firebaseapp.com",
    projectId: "scooby-425119",
    storageBucket: "scooby-425119.appspot.com",
    messagingSenderId: "928285475054",
    appId: "1:928285475054:web:aaffcc80ca8b2553ed73ff"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

// Pass your reCAPTCHA v3 site key (public key) to activate(). Make sure this
// key is the counterpart to the secret key you set in the Firebase console.
/* exported appCheck */
// eslint-disable-next-line
const appCheck = initializeAppCheck(app, {
    provider: new ReCaptchaV3Provider('6LfTuu4pAAAAAB9vs84Eul6vhWXu-DzUk09UZVqz'),

    // Optional argument. If true, the SDK automatically refreshes App Check
    // tokens as needed.
    isTokenAutoRefreshEnabled: true
});

export { auth };
import React from 'react';
import { useParams } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import QRCode from 'react-qr-code';
import useFetchItemDetails from '../Hooks/useFetchItemDetails'; // Import the custom hook

const ItemQRCode: React.FC = () => {
    const { id = '' } = useParams<{ id: string }>();
    const { item, loading } = useFetchItemDetails(id);

    if (loading) {
        return (
            <div className="d-flex justify-content-center align-items-center vh-100">
                <Spinner animation="border" />
            </div>
        );
    }

    if (!item) {
        return <p>Item not found</p>;
    }

    return (
        <QRCode value={`${window.location.origin}/item/${item.id}`} />
    );
};

export default ItemQRCode;

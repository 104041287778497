import React from 'react';

interface TextFieldProps {
    label: string;
    value: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    required?: boolean;
}

const TextField: React.FC<TextFieldProps> = ({ label, value, onChange, required = false }) => {
    return (
        <div className="mb-3">
            <label className="form-label">{label}</label>
            <input
                type="text"
                className="form-control"
                value={value}
                onChange={onChange}
                required={required}
            />
        </div>
    );
};

export default TextField;

// src/App.tsx
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './Pages/Login';
import PrivateRoute from './Hooks/PrivateRoute';
import Dashboard from './Pages/Dashboard';
import ItemDetails from './Pages/ItemDetails';
import ItemQRCode from './Pages/ItemQRCode'; // Import the QR code page component

// import other components like Dashboard

const App: React.FC = () => {
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route element={<PrivateRoute />}>
          {/* Protected routes go here */}
          <Route path="/" element={<Dashboard />} />
          <Route path="/dashboard/*" element={<Dashboard />} />
          <Route path="/item/:id" element={<ItemDetails />} />
          <Route path="/item/:id/qr" element={<ItemQRCode />} />
        </Route>
      </Routes>
    </Router>
  );
};

export default App;
import React from 'react';
import Masonry from 'react-masonry-css';
import InventoryCard from './InventoryCard';
import { InventoryItem } from '../Hooks/useFetchInventory';

interface MasonryGridProps {
    items: InventoryItem[];
}

const breakpointColumnsObj = {
    default: 4,
    1100: 3,
    700: 2,
    500: 1,
};

const MasonryGrid: React.FC<MasonryGridProps> = ({ items }) => {
    return (
        <Masonry
            breakpointCols={breakpointColumnsObj}
            className="my-masonry-grid"
            columnClassName="my-masonry-grid_column"
        >
            {items.map((item) => (
                <InventoryCard key={item.id} item={item} />
            ))}
        </Masonry>
    );
};

export default MasonryGrid;

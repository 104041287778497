import React from 'react';

interface SelectFieldProps {
    label: string;
    value: string;
    options: string[];
    onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
    required?: boolean;
}

const SelectField: React.FC<SelectFieldProps> = ({ label, value, options, onChange, required = false }) => {
    return (
        <div className="mb-3">
            <label className="form-label">{label}</label>
            <select className="form-select" value={value} onChange={onChange} required={required}>
                <option value="">Select a category</option>
                {options.map((option) => (
                    <option key={option} value={option}>
                        {option}
                    </option>
                ))}
            </select>
        </div>
    );
};

export default SelectField;

import { getFirestore, doc, getDoc } from 'firebase/firestore';
import { v4 as uuidv4 } from 'uuid';

const generateUniqueID = async (userId: string): Promise<string> => {
    const firestore = getFirestore();
    let uniqueID: string;
    let docExists: boolean;

    do {
        uniqueID = uuidv4().slice(0, 5);
        uniqueID = uniqueID.toUpperCase();
        const itemRef = doc(firestore, `users/${userId}/items/${uniqueID}`);
        const docSnapshot = await getDoc(itemRef);
        docExists = docSnapshot.exists();
    } while (docExists);

    return uniqueID;
};

export default generateUniqueID;
import React, { useState } from 'react';
import { getFirestore, doc, setDoc, serverTimestamp } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import categories from '../categories.json';
import { useAuth } from '../Hooks/AuthContext';
import TextField from '../Components/TextField';
import TextArea from '../Components/TextArea';
import SelectField from '../Components/SelectField';
import NumberField from '../Components/NumberField';
import DateField from '../Components/DateField';
import ImageUpload from '../Components/ImageUpload';
import { Spinner } from 'react-bootstrap';
import generateUniqueID from '../Hooks/UUID';
import moment from 'moment';
import ConfirmationModal from '../Components/ConfirmationModal';

const AddInventory: React.FC = () => {
    const { currentUser } = useAuth();

    var currDate = moment().format('YYYY-MM-DD');

    const [name, setName] = useState('');
    const [category, setCategory] = useState('');
    const [price, setPrice] = useState('');
    const [date, setDate] = useState(currDate);
    const [description, setDescription] = useState('');
    const [measurements, setMeasurements] = useState('');
    const [location, setLocation] = useState('');
    const [images, setImages] = useState<FileList | null>(null);
    const [loading, setLoading] = useState(false); // Add loading state
    const [modalShow, setModalShow] = useState(false); // State for modal visibility
    const [success, setSuccess] = useState(false); // State to track success or failure

    const firestore = getFirestore();
    const storage = getStorage();

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setLoading(true); // Set loading to true on form submission

        if (!currentUser) {
            console.error('User is not authenticated');
            setLoading(false); // Set loading to false if not authenticated
            return;
        }

        try {

            const userId = currentUser.uid;
            const uniqueID = await generateUniqueID(userId); // Generate a unique ID
            const itemRef = doc(firestore, `users/${userId}/items/${uniqueID}`);

            const imageUrls: { original: string; thumbnail: string }[] = [];
            if (images) {
                for (const image of Array.from(images)) {
                    const imageRef = ref(storage, `users/${userId}/items/${uniqueID}/${image.name}`);
                    await uploadBytes(imageRef, image);
                    const imageUrl = await getDownloadURL(imageRef);
                    const regex = /(\.[a-zA-Z0-9]+)(?=\?alt=media&token=.*$)/;

                    const thumbnailUrl = imageUrl.replace(regex, '_200x200.jpeg');

                    imageUrls.push({ original: imageUrl, thumbnail: thumbnailUrl });
                }
            }

            await setDoc(itemRef, {
                name,
                category,
                price: parseFloat(price),
                date: date ? new Date(date) : serverTimestamp(),
                description,
                measurements,
                location,
                images: imageUrls,
                createdAt: serverTimestamp(),
            });

            // Clear form fields
            setName('');
            setCategory('');
            setPrice('');
            setDate(currDate);
            setDescription('');
            setMeasurements('');
            setLocation('');
            setImages(null);
            setSuccess(true); // Set success state
        } catch (error) {
            console.error('Error adding item to inventory:', error);
            setSuccess(false); // Set failure state
        } finally {
            setLoading(false); // Set loading to false after submission
            setModalShow(true); // Show modal after submission
        }
    };

    return (
        <>
            <form onSubmit={handleSubmit}>
                <TextField label="Item Name" value={name} onChange={(e) => setName(e.target.value)} required />
                <SelectField label="Category" value={category} options={categories} onChange={(e) => setCategory(e.target.value)} />
                <NumberField label="Purchase Price" value={price} onChange={(e) => setPrice(e.target.value)} required />
                <DateField label="Purchase Date" value={date} onChange={(e) => setDate(e.target.value)} required />
                <TextArea label="Description" value={description} onChange={(e) => setDescription(e.target.value)} />
                <TextArea label="Measurements" value={measurements} onChange={(e) => setMeasurements(e.target.value)} />
                <ImageUpload onChange={(e) => setImages(e.target.files)} />
                <TextField label="Storage Location" value={location} onChange={(e) => setLocation(e.target.value)} />
                <button type="submit" className="btn btn-primary" disabled={loading}>
                    {loading ? <Spinner animation="border" size="sm" /> : 'Add Item'}
                </button>
            </form>
            <ConfirmationModal show={modalShow} onHide={() => setModalShow(false)} success={success} />
        </>
    );
};

export default AddInventory;
import { useState, useEffect } from 'react';
import { getFirestore, collection, query, getDocs } from 'firebase/firestore';
import { useAuth } from './AuthContext';

export interface InventoryItem {
    id: string;
    category: string;
    createdAt: any;
    date: any;
    description: string;
    images: { original: string; thumbnail: string }[];
    location: string;
    measurements: string;
    name: string;
    price: number;
    descriptions: [string];
}


const useFetchInventory = () => {
    const { currentUser } = useAuth();
    const [inventory, setInventory] = useState<InventoryItem[]>([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchInventory = async () => {
            if (!currentUser) return;

            const firestore = getFirestore();
            const inventoryRef = collection(firestore, `users/${currentUser.uid}/items`);
            const q = query(inventoryRef);
            const querySnapshot = await getDocs(q);

            const items = querySnapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
            })) as InventoryItem[];

            setInventory(items);
            setLoading(false);
        };

        fetchInventory();
    }, [currentUser]);

    return { inventory, loading };
};

export default useFetchInventory;

import React from 'react';

interface ImageUploadProps {
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const ImageUpload: React.FC<ImageUploadProps> = ({ onChange }) => {
    return (
        <div className="mb-3">
            <label className="form-label">Upload Images</label>
            <input
                type="file"
                className="form-control"
                multiple
                onChange={onChange}
            />
        </div>
    );
};

export default ImageUpload;

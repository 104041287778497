import { useState, useEffect } from 'react';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import { InventoryItem } from './useFetchInventory'; // Import InventoryItem from the existing hook
import { useAuth } from './AuthContext';

const useFetchItemDetails = (itemId: string) => {
    const [item, setItem] = useState<InventoryItem | null>(null);
    const [loading, setLoading] = useState(true);
    const { currentUser } = useAuth();
    const userId = currentUser?.uid;

    useEffect(() => {
        const fetchItem = async () => {
            const firestore = getFirestore();
            const itemRef = doc(firestore, `users/${userId}/items/${itemId}`);
            console.log(`users/${userId}/items/${itemId}`)
            const itemSnapshot = await getDoc(itemRef);

            if (itemSnapshot.exists()) {
                setItem({ id: itemSnapshot.id, ...itemSnapshot.data() } as InventoryItem);
            }

            setLoading(false);
        };

        fetchItem();
    }, [userId, itemId]);

    return { item, loading };
};

export default useFetchItemDetails;
// src/AddInventory.tsx
import React from 'react';

const AddInventory: React.FC = () => {
    return (
        <div>
            <h2>Search Inventory</h2>
            {/* Add Inventory Form and Logic */}
        </div>
    );
};

export default AddInventory;

import React, { useState, useEffect, useCallback } from 'react';
import useFetchInventory, { InventoryItem } from '../Hooks/useFetchInventory';
import MasonryGrid from '../Components/MasonryGrid';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Spinner, Dropdown, DropdownButton } from 'react-bootstrap';

const ITEMS_PER_PAGE = 10;

const ManageInventory: React.FC = () => {
  const { inventory, loading } = useFetchInventory();
  const [currentItems, setCurrentItems] = useState<InventoryItem[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedCategory, setSelectedCategory] = useState<string>('All Categories');

  useEffect(() => {
    if (!loading) {
      filterAndSetItems(inventory, selectedCategory, 1, true);
    }
  }, [loading, inventory, selectedCategory]); // eslint-disable-line react-hooks/exhaustive-deps

  const filterAndSetItems = useCallback((items: InventoryItem[], category: string, page: number, reset: boolean = false) => {
    const filteredItems = category === 'All Categories'
      ? items
      : items.filter(item => item.category === category);

    const startIndex = (page - 1) * ITEMS_PER_PAGE;
    const newItems = filteredItems.slice(startIndex, startIndex + ITEMS_PER_PAGE);
    setCurrentItems(reset ? newItems : (prevItems) => [...prevItems, ...newItems]);
  }, []);

  const fetchMoreData = () => {
    const nextPage = currentPage + 1;
    setCurrentPage(nextPage);
    filterAndSetItems(inventory, selectedCategory, nextPage);
  };

  const handleCategoryChange = (category: string) => {
    setSelectedCategory(category);
    setCurrentPage(1);
    filterAndSetItems(inventory, category, 1, true);
  };

  const categories: string[] = ['All Categories', ...Array.from(new Set(inventory.map(item => item.category)))];

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <div className="mb-4">
        <DropdownButton id="dropdown-basic-button" title={selectedCategory}>
          {categories.map((category, index) => (
            <Dropdown.Item key={index} onClick={() => handleCategoryChange(category)}>
              {category}
            </Dropdown.Item>
          ))}
        </DropdownButton>
      </div>
      <InfiniteScroll
        dataLength={currentItems.length}
        next={fetchMoreData}
        hasMore={currentItems.length < inventory.length}
        loader={
          <div style={{ display: 'flex', justifyContent: 'center', padding: '20px' }}>
            <Spinner animation="border" />
          </div>
        }
        endMessage={<p className="text-center">No more items to display</p>}
        scrollableTarget="scrollableDiv"
      >
        <div id="scrollableDiv" style={{ overflow: 'auto', height: '80vh' }}>
          <MasonryGrid items={currentItems} />
        </div>
      </InfiniteScroll>
    </div>
  );
};

export default ManageInventory;
